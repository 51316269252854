* {
    scroll-behavior: smooth;
    outline: none;
}

body {
    font-weight: 400;
    font-style: normal;
    background-color: #e5ded2;
}

h1.sectionTitle {
    color: #000;
    font-size: 26px;
    font-weight: 500;
    margin-left: 16px;
    margin-top: 30px;
}

.sectionBar {
    background-color: var(--primary-color);
    width: 80px;
    height: 2px;
    margin-left: 16px;
    margin-top: 5px;
}

.languageSelector {
    background: none!important;
    color: #888!important;
    border-radius: 0!important;
    padding: 16px 30px!important;
}

.languageSelector:hover {
    background: rgba(158, 158, 158, 0.2)!important;

}

@keyframes smooth-appear-from-left {
    to{
        left: 0;
        opacity:1;
    }
}

@keyframes smooth-appear-from-top {
    to{
        top: 0;
        opacity:1;
    }
}


.carouselTitle {
    left: -30px;
    opacity: 0;
    position: relative;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: var(--tertiary-color);
    animation: smooth-appear-from-left 1s ease forwards;
}

.carouselSubtitle {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 30px;
    color: var(--tertiary-color);
    line-height: 40px;
    opacity: 0;
    position: relative;
    top: -30px;
    animation: smooth-appear-from-top 1s ease forwards;
}

.carouselButton {
    height: 50px!important;
    text-transform: uppercase;
    left: -30px;
    opacity: 0;
    position: relative;
    animation: smooth-appear-from-left 1s ease forwards;
}

.carouselTitleHidden, .carouselSubtitleHidden, .carouselButtonHidden {
    opacity: 0;
}

.wrapper {
    max-width: 1260px;
    margin: 0 auto;
}

.cover {
    object-fit: contain;
    height: 320px;
    width: 100%;
}

.productCardTitle {
    color: #222;
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.productCardType {
    text-transform: uppercase;
    background-color: var(--primary-color);
    font-size: 12px;
    margin-top: 20px;
    font-weight: 600;
    text-align: right;
    padding: 5px 10px;
    color: var(--tertiary-color);
    display: inline-block;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
}

#colophon::before {
    content: "";
    background: var(--primary-color);
    -webkit-mask: url(/assets/footer-pattern.png) repeat-x;
    mask: url(/assets/footer-pattern.png) repeat-x;
    height: 5px;
    width: 100%;
    display: block;
    top: -29px;
    position: relative;
}

h3.footerTitle {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 22px;
}


h3.footerLine {
    margin-left: 15px;
    text-align: left;
}

p.footerLine {
    margin-left: 15px;
    text-align: left;
    font-size: 13px;
    color: var(--tertiary-color);
    opacity: 0.7;
}

p.footerLine a {
    margin-left: 0;
    text-align: left;
    font-size: 13px;
    color: var(--tertiary-color);
}

.noMargin {
    margin-left: 0!important;
}

p.footerLine a:hover {
    text-decoration: underline;
}

.searchButton:disabled {
    cursor: default!important;
    color: var(--tertiary-color)!important;
}

.searchButton:active {
    border-top-left-radius: 0!important;
}

.productFilter {
    width: 250px;
    border: 1px solid #eee;
    /*border-right: 1px solid #A92216!important;*/
    /*border-top-right-radius: 0px!important;*/
    /*border-bottom-right-radius: 0!important;*/

    color: #666;
}

.productFilter:active {
    border: 1px solid #eee;
    /*border-right: 1px solid #A92216!important;*/
    /*border-top-right-radius: 0!important;*/
    /*border-bottom-right-radius: 0!important;*/
}

:where(.css-dev-only-do-not-override-15qwuhm).ant-select-compact-item:not(.ant-select-compact-last-item) {
    margin-inline-end: 0!important;
}

.productImage {
   margin: 0 auto!important;
    object-fit: contain;
    height: 230px!important;
}

.contactTextArea {
    resize: none!important;
}

.contactTextArea:hover, .contactTextArea:focus{
    border-color: var(--primary-color)!important;
    box-shadow: 0 0 0 2px rgba(169, 34, 22, 0.1)!important;
}

.myCard {
    opacity: 0.9;
    padding: 20px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
}
.myCard:hover {
    cursor: pointer;
    border: 1px solid #dedede;
    opacity: 1.0;
}

.footerItem {
    font-size: 32px;
    font-weight: normal;
    opacity: 0.7;
}

span.statTitle {
    font-size: 24px;
}