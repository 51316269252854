* {
    scroll-behavior: smooth;
    outline: none;
}

body {
    font-weight: 400;
    font-style: normal;
    background-color: #e5ded2;
}

h1.sectionTitle {
    color: #000;
    font-size: 26px;
    font-weight: 500;
    margin-left: 16px;
    margin-top: 30px;
}

.sectionBar {
    background-color: var(--primary-color);
    width: 80px;
    height: 2px;
    margin-left: 16px;
    margin-top: 5px;
}

.languageSelector {
    background: none!important;
    color: #888!important;
    border-radius: 0!important;
    padding: 16px 30px!important;
}

.languageSelector:hover {
    background: rgba(158, 158, 158, 0.2)!important;

}

@keyframes smooth-appear-from-left {
    to{
        left: 0;
        opacity:1;
    }
}

@keyframes smooth-appear-from-top {
    to{
        top: 0;
        opacity:1;
    }
}


.carouselTitle {
    left: -30px;
    opacity: 0;
    position: relative;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: var(--tertiary-color);
    animation: smooth-appear-from-left 1s ease forwards;
}

.carouselSubtitle {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 30px;
    color: var(--tertiary-color);
    line-height: 40px;
    opacity: 0;
    position: relative;
    top: -30px;
    animation: smooth-appear-from-top 1s ease forwards;
}

.carouselButton {
    height: 50px!important;
    text-transform: uppercase;
    left: -30px;
    opacity: 0;
    position: relative;
    animation: smooth-appear-from-left 1s ease forwards;
}

.carouselTitleHidden, .carouselSubtitleHidden, .carouselButtonHidden {
    opacity: 0;
}

.wrapper {
    max-width: 1260px;
    margin: 0 auto;
}

.cover {
    object-fit: contain;
    height: 320px;
    width: 100%;
}

.productCardTitle {
    color: #222;
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.productCardType {
    text-transform: uppercase;
    background-color: var(--primary-color);
    font-size: 12px;
    margin-top: 20px;
    font-weight: 600;
    text-align: right;
    padding: 5px 10px;
    color: var(--tertiary-color);
    display: inline-block;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
}

#colophon::before {
    content: "";
    background: var(--primary-color);
    -webkit-mask: url(/assets/footer-pattern.png) repeat-x;
    mask: url(/assets/footer-pattern.png) repeat-x;
    height: 5px;
    width: 100%;
    display: block;
    top: -29px;
    position: relative;
}

h3.footerTitle {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 22px;
}


h3.footerLine {
    margin-left: 15px;
    text-align: left;
}

p.footerLine {
    margin-left: 15px;
    text-align: left;
    font-size: 13px;
    color: var(--tertiary-color);
    opacity: 0.7;
}

p.footerLine a {
    margin-left: 0;
    text-align: left;
    font-size: 13px;
    color: var(--tertiary-color);
}

.noMargin {
    margin-left: 0!important;
}

p.footerLine a:hover {
    text-decoration: underline;
}

.searchButton:disabled {
    cursor: default!important;
    color: var(--tertiary-color)!important;
}

.searchButton:active {
    border-top-left-radius: 0!important;
}

.productFilter {
    width: 250px;
    border: 1px solid #eee;
    /*border-right: 1px solid #A92216!important;*/
    /*border-top-right-radius: 0px!important;*/
    /*border-bottom-right-radius: 0!important;*/

    color: #666;
}

.productFilter:active {
    border: 1px solid #eee;
    /*border-right: 1px solid #A92216!important;*/
    /*border-top-right-radius: 0!important;*/
    /*border-bottom-right-radius: 0!important;*/
}

:where(.css-dev-only-do-not-override-15qwuhm).ant-select-compact-item:not(.ant-select-compact-last-item) {
    -webkit-margin-end: 0!important;
            margin-inline-end: 0!important;
}

.productImage {
   margin: 0 auto!important;
    object-fit: contain;
    height: 230px!important;
}

.contactTextArea {
    resize: none!important;
}

.contactTextArea:hover, .contactTextArea:focus{
    border-color: var(--primary-color)!important;
    box-shadow: 0 0 0 2px rgba(169, 34, 22, 0.1)!important;
}

.myCard {
    opacity: 0.9;
    padding: 20px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
}
.myCard:hover {
    cursor: pointer;
    border: 1px solid #dedede;
    opacity: 1.0;
}

.footerItem {
    font-size: 32px;
    font-weight: normal;
    opacity: 0.7;
}

span.statTitle {
    font-size: 24px;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}.__variable_d65c78 {--font-inter: '__Inter_d65c78', '__Inter_Fallback_d65c78'
}

